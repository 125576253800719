<template>
  <!-- #site-wrapper start -->
  <div v-if="user" id="site-wrapper" class="site-wrapper panel page-invoice-listing common-elements">
    <!-- #header start -->
    <!-- #header start -->
    <NavBar />
    <!-- #header end -->
    <!-- #wrapper-content start -->
    <div id="wrapper-content" class="wrapper-content pt-0 pb-0">
      <div class="page-wrapper d-flex flex-wrap flex-xl-nowrap">

        <LeftSideBar />

        <div class="page-container">
          <div class="container-fluid">

            <div class="page-content-wrapper d-flex flex-column h-100">
              <h1 class="font-size-h4 mb-4 font-weight-normal">My Settings</h1>
              <div class="page-content">
               <SettingTabs v-bind:partner-id="$route.params.partnerId"  />
              </div>

            </div>

          </div>
        </div>
      </div>

    </div>
     <FooterBar />
    <!-- #wrapper-content end -->
  </div>


  <div v-else>
    <LoadingScreen />
  </div>


</template>

<script>
  import {
    mapGetters,
    mapActions
  } from "vuex";
  import NavBar from "../Layouts/NavBar";
  import LeftSideBar from "../Layouts/LeftSideBar";
  import FooterBar from "../Layouts/FooterBar";
  import LoadingScreen from "../Layouts/LoadingScreen";
  import SettingTabs from "../MySettings/SettingTabs"
  export default {
    name: "MySetting",

    components: {
      NavBar,
      LeftSideBar,
      FooterBar,
      SettingTabs,
      LoadingScreen
    },
        computed: {
      ...mapGetters("auth", ["user"]),
    },
        mounted() {
        },
        methods: {
     ...mapActions("auth"),
        },
  };
</script>
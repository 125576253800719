<template>
  <div>
    <div class="form-group business-hours mb-2">
      <div
        class="
          text-dark
          font-weight-semibold font-size-md
          mb-3
          lh-12
          border-bottom
          pb-2
        "
      >
        <div class="row">
          <div class="col-8">GridNav API Key</div>
          <div class="col-4">
            <span
              style="cursor: pointer"
              :disabled="isLoading ? '' : isLoading"
              @click="reGenerate()"
              class="
                btn btn-primary
                text-primary text-white text-decoration-none
                float-right
              "
            >
              <span v-if="!isLoading">Re-generate</span>
              <span v-if="isLoading">regenerating...</span>
            </span>
          </div>
        </div>
      </div>

      <div v-if="gridNavApiKey" class="row mb-3 lh-12 pb-2">
        <div class="col-10"
          >
                   
                   <textarea class="form-control" v-model="gridNavApiKey"></textarea>

                    <input
                      type="hidden"
                      id="gridNavKey"
                      :value="gridNavApiKey"
                    />
            
            </div>
            <div class="col-2">
                <span
                    class="btn btn-info text-white copy-btn ml-auto float-right"
                    @click.stop.prevent="copyGridNavKey"
                  >
                    Copy
                  </span>
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "GridNavApiKey",
  props: ["partnerId"],
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters("business", ["currentBusiness"]),
    ...mapGetters("gridNavApiKey", ["gridNavApiKey"]),
    ...mapGetters(["errors", "isSuccess"]),
  },
  mounted() {
    this.$store.commit("setErrors", {});
    this.$store.commit("setIsSuccess", false);
    this.getPartnerBusiness(this.partnerId);
    this.getGridNavApiKey(this.partnerId).then(() => {this.isLoading = false});
  },
  methods: {
    ...mapActions("business", ["getPartnerBusiness"]),
    ...mapActions("gridNavApiKey", [
      "sendRegenerateGridNavApiKeyRequest",
      "getGridNavApiKey",
    ]),
    reGenerate() {
      this.isLoading = true;
      this.sendRegenerateGridNavApiKeyRequest({
        partnerId: this.currentBusiness.partnerId,
        userId: this.currentBusiness.userId,
        partnerBusinessName: this.currentBusiness.businessName,
      }).then(() => {
        this.isLoading = false;
        if (this.isSuccess) {
          this.$notify({
            group: "app",
            type: "success",
            title: "Done",
            text: "Re-generated successfully",
            duration: 10000,
            speed: 1000,
          });
        }
      });
    },
    copyGridNavKey() {
      let gridNavKeyToCopy = document.querySelector("#gridNavKey");
      gridNavKeyToCopy.setAttribute("type", "text");
      gridNavKeyToCopy.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.$notify({
            group: "app",
            type: "success",
            title: "Done",
            text: "Copied!",
            duration: 10000,
            speed: 1000,
          });
        }
      } catch (err) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Oops",
          text: "unable to copy",
          duration: 10000,
          speed: 1000,
        });
      }

      /* unselect the range */
      gridNavKeyToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>
<template>
    <div>
       <div class="form-group business-hours mb-2">
												<div class="text-dark font-weight-semibold font-size-md mb-3 lh-12 border-bottom pb-2">Privacy Settings
												</div>
												
												<div v-if="privacy && privacy.showEmailInDirectory" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Show Email In Directory </span>
                                                    <span class="col-6 col-md-6">Click disable button to hide email in directory</span>
													<span style="cursor:pointer"  :disabled="showEmailInDirectory? '' : showEmailInDirectory"  @click="updatePrivacy('showEmailInDirectory')" class="col-2 col-md-2 text-danger text-decoration-none text-right">
                                                         <span v-if="!showEmailInDirectory">Disable</span>
                                                        <span v-if="showEmailInDirectory">Updating...</span>
                                                    </span>
												</div>

                                                <div v-if="privacy && !privacy.showEmailInDirectory" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Show Email In Directory </span>
                                                    <span class="col-6 col-md-6">Click enable button to show email in directory</span>
													<span style="cursor:pointer"  :disabled="showEmailInDirectory? '' : showEmailInDirectory"  @click="updatePrivacy('showEmailInDirectory')" class="col-2 col-md-2 text-info text-decoration-none text-right"> 
                                                        <span v-if="!showEmailInDirectory"> Enable </span>
                                                        <span v-if="showEmailInDirectory">Updating...</span>
                                                    </span>
												</div>

                                                <div v-if="privacy && privacy.showPhoneNumberInDirectory" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Show Phone In Directory </span>
                                                    <span class="col-6 col-md-6">Click disable button to hide phone in directory</span>
													<span style="cursor:pointer"  :disabled="showPhoneNumberInDirectory? '' : showPhoneNumberInDirectory"  @click="updatePrivacy('showPhoneNumberInDirectory')" class="col-2 col-md-2 text-danger text-decoration-none text-right">
                                                         <span v-if="!showPhoneNumberInDirectory">Disable</span>
                                                        <span v-if="showPhoneNumberInDirectory">Updating...</span>
                                                    </span>
												</div>

                                                <div v-if="privacy && !privacy.showPhoneNumberInDirectory" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Show Phone In Directory </span>
                                                    <span class="col-6 col-md-6">Click enable button to show phone in directory</span>
													<span style="cursor:pointer"  :disabled="showPhoneNumberInDirectory? '' : showPhoneNumberInDirectory"  @click="updatePrivacy('showPhoneNumberInDirectory')" class="col-2 col-md-2 text-info text-decoration-none text-right"> 
                                                        <span v-if="!showPhoneNumberInDirectory"> Enable </span>
                                                        <span v-if="showPhoneNumberInDirectory">Updating...</span>
                                                    </span>
												</div>


                                                 <div v-if="privacy && privacy.showBranches" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Show Branches In Directory </span>
                                                    <span class="col-6 col-md-6">Click disable button to hide branches in directory</span>
													<span style="cursor:pointer"  :disabled="showBranches? '' : showBranches"  @click="updatePrivacy('showBranches')" class="col-2 col-md-2 text-danger text-decoration-none text-right">
                                                         <span v-if="!showBranches">Disable</span>
                                                        <span v-if="showBranches">Updating...</span>
                                                    </span>
												</div>

                                                <div v-if="privacy && !privacy.showBranches" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Show Branches In Directory </span>
                                                    <span class="col-6 col-md-6">Click enable button to show branches in directory</span>
													<span style="cursor:pointer"  :disabled="showBranches? '' : showBranches"  @click="updatePrivacy('showBranches')" class="col-2 col-md-2 text-info text-decoration-none text-right"> 
                                                        <span v-if="!showBranches"> Enable </span>
                                                        <span v-if="showBranches">Updating...</span>
                                                    </span>
												</div>

                                                 <div v-if="privacy && privacy.allowUnsusedCreditRollover" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Unsused Credit Rollover </span>
                                                    <span class="col-6 col-md-6">Click disallow button to disallow Unsused Credit Rollover</span>
													<span style="cursor:pointer"  :disabled="allowUnsusedCreditRollover? '' : allowUnsusedCreditRollover"  @click="updatePrivacy('allowUnsusedCreditRollover')" class="col-2 col-md-2 text-danger text-decoration-none text-right">
                                                         <span v-if="!allowUnsusedCreditRollover">Disallow</span>
                                                        <span v-if="allowUnsusedCreditRollover">Updating...</span>
                                                    </span>
												</div>

                                                <div v-if="privacy && !privacy.allowUnsusedCreditRollover" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Unsused Credit Rollover </span>
                                                    <span class="col-6 col-md-6">Click allow button to allow Unsused Credit Rollover</span>
													<span style="cursor:pointer"  :disabled="allowUnsusedCreditRollover? '' : allowUnsusedCreditRollover"  @click="updatePrivacy('allowUnsusedCreditRollover')" class="col-2 col-md-2 text-info text-decoration-none text-right"> 
                                                        <span v-if="!allowUnsusedCreditRollover"> Allow </span>
                                                        <span v-if="allowUnsusedCreditRollover">Updating...</span>
                                                    </span>
												</div>

                                                 <div v-if="privacy && privacy.allowUnsusedSubscriptionRollover" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Unsused Subscription Rollover </span>
                                                    <span class="col-6 col-md-6">Click disallow button to disallow Unsused Subscription Rollover </span>
													<span style="cursor:pointer"  :disabled="allowUnsusedSubscriptionRollover? '' : allowUnsusedSubscriptionRollover"  @click="updatePrivacy('allowUnsusedSubscriptionRollover')" class="col-2 col-md-2 text-danger text-decoration-none text-right">
                                                         <span v-if="!allowUnsusedSubscriptionRollover">Disallow</span>
                                                        <span v-if="allowUnsusedSubscriptionRollover">Updating...</span>
                                                    </span>
												</div>

                                                <div v-if="privacy && !privacy.allowUnsusedSubscriptionRollover" class="row lh-18 border-bottom pb-2">
													<span class="font-weight-semibold col-4 col-md-4">Unsused Subscription Rollover  </span>
                                                    <span class="col-6 col-md-6">Click allow button to allow Unsused Subscription Rollover </span>
													<span style="cursor:pointer"  :disabled="allowUnsusedSubscriptionRollover? '' : allowUnsusedSubscriptionRollover"  @click="updatePrivacy('allowUnsusedSubscriptionRollover')" class="col-2 col-md-2 text-info text-decoration-none text-right"> 
                                                        <span v-if="!allowUnsusedSubscriptionRollover"> Allow </span>
                                                        <span v-if="allowUnsusedSubscriptionRollover">Updating...</span>
                                                    </span>
												</div>


												
											</div>
    </div>


</template>

<script>
    import {
        mapGetters,
        mapActions
    } from "vuex";

    export default {
        name: "Privacy",
        props: ["partnerId"],
        	data: function () {
			return {
				showEmailInDirectory: false,
				showPhoneNumberInDirectory: false,
                showBranches:false,
                allowUnsusedCreditRollover:false,
                allowUnsusedSubscriptionRollover:false
			};
		},
        computed: {
            ...mapGetters("privacy", ['privacy']),
            ...mapGetters(["errors", "isSuccess","isLoading"])
        },
        mounted() {
            this.$store.commit("setErrors", {});
            this.$store.commit("setIsLoading", false);
           this.$store.commit("setIsSuccess", false);
            this.getPrivacy(this.partnerId);
        },
        methods: {
            ...mapActions("privacy", ["getPrivacy","sendUpdatePrivacyRequest"]),
              updatePrivacy(condition) {

                this.showEmailInDirectory =false;
                this.showPhoneNumberInDirectory =false;
                this.showBranches =false;
                this.allowUnsusedCreditRollover=false;
                this.allowUnsusedSubscriptionRollover=false;

                if(condition === 'showEmailInDirectory'){
                    this.showEmailInDirectory =true;
                    this.privacy.showEmailInDirectory=!this.privacy.showEmailInDirectory;
                }
                 if(condition === 'showPhoneNumberInDirectory'){
                      this.showPhoneNumberInDirectory =true;
                    this.privacy.showPhoneNumberInDirectory=!this.privacy.showPhoneNumberInDirectory;
                }
                if(condition === 'showBranches'){
                      this.showBranches =true;
                    this.privacy.showBranches=!this.privacy.showBranches;
                }
                 if(condition === 'allowUnsusedCreditRollover'){
                      this.allowUnsusedCreditRollover =true;
                    this.privacy.allowUnsusedCreditRollover=!this.privacy.allowUnsusedCreditRollover;
                }
                if(condition === 'allowUnsusedSubscriptionRollover'){
                      this.allowUnsusedSubscriptionRollover =true;
                    this.privacy.allowUnsusedSubscriptionRollover=!this.privacy.allowUnsusedSubscriptionRollover;
                }
                
                

        this.sendUpdatePrivacyRequest(this.privacy).then(async () => {
               this.showEmailInDirectory =false;
                this.showPhoneNumberInDirectory =false;
                this.showBranches=false;
                this.allowUnsusedCreditRollover=false;
                this.allowUnsusedSubscriptionRollover=false;

                    if (this.isSuccess) {

                                this.$notify({
                                group: "app",
                                type: "success",
                                title: "Done",
                                text: "Privacy updated successfully",
                                duration: 10000,
                                speed: 1000,
                                });

                    }
        });
      },

        },
    };
</script>
<template>

    <div>

        <div class="page-content col-lg-12 border-0">
            <div class="page-content-inner border-0">
                <div id="tabs">

                    <div id="section-tab-vertical" class="pb-8">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="nav flex-md-column nav-pills tab-style-05 tab-vertical" id="v-pills-tab"
                                    role="tablist" aria-orientation="vertical">
                                    <a class="nav-link active" id="v-pills-tab-01-tab" data-toggle="pill"
                                        href="#v-pills-tab-01" role="tab" aria-controls="v-pills-tab-01"
                                        aria-selected="false">Privacy</a>

                                         <a class="nav-link" id="v-pills-tab-02-tab" data-toggle="pill"
                                        href="#v-pills-tab-02" role="tab" aria-controls="v-pills-tab-02"
                                        aria-selected="false">GridNav API Key</a>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="tab-content" id="v-pills-tabContent">

                                    <div class="tab-pane fade active show" id="v-pills-tab-01" role="tabpanel"
                                        aria-labelledby="v-pills-tab-01-tab">
                                        <Privacy v-bind:partner-id="partnerId" />
                                    </div>

                                    <div class="tab-pane fade" id="v-pills-tab-02" role="tabpanel"
                                        aria-labelledby="v-pills-tab-02-tab">
                                        <GridNavApiKey v-bind:partner-id="partnerId" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- #section-tab-vertical end -->
                </div>

            </div>

        </div>

    </div>


</template>

<script>
    import Privacy from "../MySettings/Setting/Privacy"
    import GridNavApiKey from "../MySettings/Setting/GridNavApiKey"
    export default {
        name: "SettingTabs",
        props: ["partnerId"],
        components: {
            Privacy,
            GridNavApiKey
        },
        computed: {},
        mounted() {},
        methods: {

        },
    };
</script>